/*****
 *
 * cookie
 *
 *****/
$(function(){
    $("#privacy-accept").on("close.bs.alert", function () {
        Cookies.set("privacy-accept", 1, { expires : 365 });
    });
});


/*****
 *
 * Equal height
 *
 *****/
$(document).ready(function(){

    $('.container').each(function(){
        var highestBox = 0;

        $(this).find('.height').each(function(){
            if($(this).height() > highestBox){
                highestBox = $(this).height();
            }
        })

        $(this).find('.height').height(highestBox);
    });


});